var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('header', {
    staticClass: "mb-1"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page " + _vm._s(_vm.currentPage) + " ")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "size": "sm",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function ($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput",
      "size": "sm"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    staticClass: "w-75",
    attrs: {
      "id": "filterInput",
      "type": "search",
      "size": "sm"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "size": "sm",
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Clear')))])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.datas,
      "fields": _vm.fieldsOwner,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(harga_dasar)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" Rp. " + _vm._s(_vm.formatRupiah(item.harga_dasar)) + " ")];
      }
    }, {
      key: "cell(show_detail)",
      fn: function (row) {
        return [_c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function ($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])];
      }
    }, {
      key: "row-details",
      fn: function (_ref2) {
        var toggleDetails = _ref2.toggleDetails,
          item = _ref2.item;
        return [_c('b-row', {
          staticClass: "mb-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('table', {
          attrs: {
            "border": "3"
          }
        }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Varian")])]), _vm._l(item.varian_item, function (data, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(" " + _vm._s(data.varian ? data.varian : "-") + " ")])]);
        })], 2)]), _c('b-col', {
          attrs: {
            "cols": "8"
          }
        }, [_c('table', {
          attrs: {
            "border": "3"
          }
        }, [_c('tr', [_c('th', [_vm._v("Satuan Dari")]), _c('th', [_vm._v("Nilai")])]), _vm._l(item.konversi, function (satuan, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v("1 " + _vm._s(satuan.satuan ? satuan.satuan.satuan : "-"))]), _c('td', [_vm._v(" " + _vm._s(_vm.formatRupiah(satuan.nilai)) + " ")])]);
        })], 2)])], 1), _c('b-button', {
          staticClass: "mb-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": toggleDetails
          }
        }, [_vm._v(" Hide ")])];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref3) {
        var index = _ref3.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(jenis)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.jenis == 1 ? "Rokok" : "Non-Rokok") + " ")];
      }
    }, {
      key: "cell(harga_jual)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.harga_jual && item.harga_jual.length > 0 ? _vm.formatRupiah(item.harga_jual[0].harga) : 0) + " ")];
      }
    }, {
      key: "cell(id_satuan)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : "-") + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('section', {
          staticClass: "d-flex justify-content-center"
        }, [_vm.allowUpdate(_vm.$route.meta.module) ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": ("edit-btn-" + (item.id)),
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/barang/edit/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _c('b-tooltip', {
          attrs: {
            "target": ("edit-btn-" + (item.id))
          }
        }, [_vm._v("Edit")]), _vm.allowDelete(_vm.$route.meta.module) ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": ("delete-btn-" + (item.id)),
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.deleteSingle(item.id);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e(), _c('b-tooltip', {
          attrs: {
            "target": ("delete-btn-" + (item.id))
          }
        }, [_vm._v("Hapus")])], 1)];
      }
    }])
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.total,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }